import React from 'react';
import emailjs from 'emailjs-com';
import './ContactUs.css';
import ContactBg from '../assets/images/contact-bg.jpg';

function ContactUs() {

  const sendFeedback = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ks2lwru', 'template_93oj4t5', e.target, 'A6iItOqCW52sQ6fWf')
      .then((result) => {
          console.log(result.text);
          alert('Feedback sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send feedback. Please try again.');
      });

    e.target.reset(); // To reset the form fields after submission
  };

  return (
    <div className="container">
      <div className="contact-top-image mb-5">
        <img src={ContactBg} className="img-fluid" alt="Contact Us" />
      </div>
      <div className="row">
        <div className="col-md-6 text-block text-center my-5">
          <h2 className="contact-heading">QUICK FEEDBACK FORM</h2>
          <form onSubmit={sendFeedback}>
            <div className="mb-3">
              <input type="text" className="form-control" name="user_name" placeholder="Enter Name (required)" required />
            </div>
            <div className="mb-3">
              <input type="email" className="form-control" name="user_email" placeholder="Enter Email (required)" required />
            </div>
            <div className="mb-3">
              <input type="text" className="form-control" name="user_phone" placeholder="Enter Phone / Mobile (required)" required />
            </div>
            <div className="mb-3">
              <textarea className="form-control" rows="4" name="message" placeholder="Write Your Message"></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Submit</button>
          </form>
        </div>
        <div className="col-md-6 text-center my-5">
          <h2 className="contact-heading">FACTORY</h2>
          <address className="address-text">
            Mishari House<br />
            912 K Wajidpur, Jajmau, Kanpur (U.P) – INDIA<br />
            +91-8318003947<br />
            +91-9839373955<br />
            <a href="mailto:info@mehtabproducts.com">manaal@mehtabproduct.com</a><br />
            <a href="mailto:sales@mehtabproducts.com">mehtabpro786@gmail.com</a><br />
          </address>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center my-5">
          <h2 className="contact-heading">LOCATION</h2>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3572.975807467443!2d80.41511535644531!3d26.424257278442383!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399c41e4d333079f%3A0xb5d219764fb77200!2sMEHTAB%20PRODUCTS!5e0!3m2!1sen!2sin!4v1701633192620!5m2!1sen!2sin"
              width="600"
              height="450"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
