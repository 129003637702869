// src/firebase.js

import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBrFZs6s75ORTwh0Hl4nLOsYcWXUpn-JeY",
    authDomain: "mehtabproducts-2bb45.firebaseapp.com",
    projectId: "mehtabproducts-2bb45",
    storageBucket: "mehtabproducts-2bb45.appspot.com",
    messagingSenderId: "633639169284",
    appId: "1:633639169284:web:92ede8430e96e17b4e7451",
    measurementId: "G-BN6JJEXLNR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const storage = getStorage(app);
const firestore = getFirestore(app);
const auth = getAuth(app);

// Optional: Initialize Analytics
const analytics = getAnalytics(app);

// Export services
export { storage, firestore, auth, analytics };
