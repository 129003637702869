import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import emailjs from 'emailjs-com';
import './ProductDetails.css';

function ProductDetails() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const db = getFirestore();

  useEffect(() => {
    const getProduct = async () => {
      try {
        const docRef = doc(db, 'products', productId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const productData = docSnap.data();
          setProduct(productData);
          setSelectedImage(productData.image || ''); // Set the first image or empty string
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    getProduct();
  }, [productId, db]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ks2lwru', 'template_xgahs0k', e.target, 'A6iItOqCW52sQ6fWf')
      .then((result) => {
          console.log(result.text);
          alert('Inquiry sent successfully!');
      }, (error) => {
          console.log(error.text);
          alert('Failed to send the inquiry. Please try again.');
      });
  };

  if (!product) {
    return <div className="text-center">Loading...</div>;
  }

  return (
    <div className="container my-5">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="card">
            <img 
              src={selectedImage || 'https://firebasestorage.googleapis.com/v0/b/mehtabproducts-2bb45.appspot.com/o/products%2Fdefault-image.jpeg?alt=media&token=default-token'} 
              className="card-img-top product-detail-image" 
              alt={product.name} 
              onError={(e) => e.target.src = 'https://firebasestorage.googleapis.com/v0/b/mehtabproducts-2bb45.appspot.com/o/products%2Fdefault-image.jpeg?alt=media&token=default-token'} // Default image on error
            />
            <div className="product-image-thumbnails">
              {product.image && (
                <img 
                  src={product.image} 
                  alt={`${product.name}-thumbnail`} 
                  className={`product-thumbnail ${selectedImage === product.image ? 'selected' : ''}`}
                  onClick={() => setSelectedImage(product.image)}
                />
              )}
            </div>
            <div className="card-body">
              <h2 className="card-title">{product.name}</h2>
              <p className="card-text">Model Number: {product.modelNumber}</p>
              <p className="card-text">Category: {product.category}</p>
              <p className="card-text">Subcategory: {product.subcategory}</p>
              <p className="card-text">Details: {product.details}</p>
            </div>
          </div>

          {/* Inquiry Form */}
          <form className="my-4" onSubmit={sendEmail}>
            <input type="hidden" name="product_name" value={product.name} />
            <input type="hidden" name="product_id" value={productId} />
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Your Name</label>
              <input type="text" className="form-control" name="user_name" required />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Your Email</label>
              <input type="email" className="form-control" name="user_email" required />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Message</label>
              <textarea className="form-control" name="message" rows="3" required></textarea>
            </div>
            <button type="submit" className="btn btn-primary">Send Inquiry</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
