import React from 'react';
import './AboutUs.css';
import AboutBg from '../assets/images/about-bg.jpg';
import About2 from '../assets/images/about-2.jpg';

function AboutUs() {
  return (
    <div className="container">
      <div className="about-top-image mb-5">
        <img src={AboutBg} className="img-fluid" alt="About Us" />
      </div>
      <div className="row">
        <div className="col-md-6 text-block text-center my-5">
          <h2>COMPANY OVERVIEW</h2>
          <h3 className='about-us-text'>Foundation</h3>
          <p className='about-us-text'>Founded in 1994 in Kanpur, India, MEHTAB PRODUCTS began as a sole proprietorship by Mr. Mehtab Alam. It has since flourished into a leading manufacturer and exporter of leather belts and goods.</p>
          <h3 className='about-us-text'>Strength</h3>
          <p className='about-us-text'>Our seasoned team of professionals utilizes advanced machinery to craft a wide variety of leather products to meet your specific needs and preferences.</p>
          <h3 className='about-us-text'>Vision</h3>
          <p className='about-us-text'>We are dedicated to providing an extensive range of leather products, featuring diverse designs, styles, finishes, and colors. Committed to quality for over 25 years, we strive to deliver only the finest leather goods.</p>
          <h3 className='about-us-text'>Mission</h3>
          <p className='about-us-text'>It is our mission to offer you a broad selection of choices, enabling you to find exactly what you seek. We take pride in catering to leather enthusiasts worldwide with our expansive range of products.</p>
        </div>
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img src={About2} className="img-fluid" alt="About Image" />
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
