import React, { useEffect, useState } from 'react';
import './Home.css';
import { Link, useNavigate } from 'react-router-dom';
import SlideBg1 from '../assets/images/slide-bg-1.jpg';
import SlideBg2 from '../assets/images/slide-bg-2.jpg';
import SlideBg3 from '../assets/images/slide-bg-3.jpg';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

function Home() {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, 'categories'));
      const fetchedCategories = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort categories with "Men's" first, then reverse alphabetical order for the rest
      fetchedCategories.sort((a, b) => {
        if (a.name === "Men's") return -1;
        if (b.name === "Men's") return 1;
        return b.name.localeCompare(a.name); // Reverse alphabetical order
      });

      setCategories(fetchedCategories);
    };

    fetchCategories();
  }, [db]);

  const handleCategoryClick = (category) => {
    navigate('/products', { state: { selectedCategory: category } });
  };

  return (
    <div className="container">
      <div className="product-showcase">
        <div id="productCarousel" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={SlideBg1} className="d-block w-100" alt="Slide 1" />
            </div>
            <div className="carousel-item">
              <img src={SlideBg2} className="d-block w-100" alt="Slide 2" />
            </div>
            <div className="carousel-item">
              <img src={SlideBg3} className="d-block w-100" alt="Slide 3" />
            </div>
          </div>
        </div>
      </div>

      <div className="welcome-section text-center my-5">
        <h2>WELCOME TO MEHTAB PRODUCTS</h2>
        <p>Mehtab Products is one of the leading manufacturer & exporter of fashion belt & leather goods in India. We have a experienced and professional team with latest machinery plant. We use all types of leather as in high wage and chrome leather.</p>
        <p>Further we are offering these leather products in various designs, styles, finishes and colors. We are a quality based organisation that aims offering superior quality leather products.</p>
        <p>Our desire is to provide you with a range of choices, styles, colors to help you find exactly what you are looking for.</p>
      </div>

      <div className="our-products-section text-center my-5">
        <h2>OUR PRODUCTS</h2>
        <div className="row">
          {categories.map(category => (
            <div key={category.id} className="col-md-3 product" onClick={() => handleCategoryClick(category.name)}>
              <img src={category.image} className="img-fluid" alt={category.name} />
              <p>{category.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
