import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Products from './pages/products/Products';
import ProductDetails from './pages/ProductDetails';
import Belts from './pages/products/Belts';
import Admin from './pages/Admin';
import ProductList from './pages/ProductList';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/products" element={<Products />} />
          <Route path="/belts" element={<Belts />} />
          <Route path="/product/:productId" element={<ProductDetails />} />
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/product-list"
            element={isAuthenticated ? <ProductList /> : <Navigate to="/admin" />}
          />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
