import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import './Products.css';

function Products() {
  const location = useLocation();
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(location.state?.selectedCategory || 'All');
  const [selectedSubcategory, setSelectedSubcategory] = useState('');
  const [subcategoryImages, setSubcategoryImages] = useState({});
  const filterLabelRef = useRef(null);
  const db = getFirestore();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories'));
        const fetchedCategories = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort categories with "Men's" first, then reverse alphabetical order for the rest
        fetchedCategories.sort((a, b) => {
          if (a.name === "Men's") return -1;
          if (b.name === "Men's") return 1;
          return b.name.localeCompare(a.name); // Reverse alphabetical order
        });

        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, [db]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        const fetchedProducts = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, [db]);

  useEffect(() => {
    const filteredByCategory = selectedCategory === 'All'
      ? products
      : products.filter(product => product.category === selectedCategory);

    if (selectedSubcategory) {
      const filteredBySubcategory = filteredByCategory.filter(product => product.subcategory === selectedSubcategory);
      setFilteredProducts(filteredBySubcategory);
    } else {
      setFilteredProducts(filteredByCategory);
    }
  }, [selectedCategory, selectedSubcategory, products]);

  useEffect(() => {
    const fetchSubcategoryImages = () => {
      const categoryDoc = categories.find(cat => cat.name === selectedCategory);
      if (categoryDoc && categoryDoc.subcategories) {
        const subcategoriesToFetch = categoryDoc.subcategories;
        const subcategoryImages = {};

        subcategoriesToFetch.forEach(subcategory => {
          subcategoryImages[subcategory.name] = subcategory.image || `https://example.com/path/to/default.jpg`;
        });

        setSubcategoryImages(subcategoryImages);
        setSubcategories(subcategoriesToFetch.map(sub => sub.name));
      }
    };

    if (selectedCategory !== 'All') {
      fetchSubcategoryImages();
    }
  }, [selectedCategory, categories]);

  useEffect(() => {
    if (selectedSubcategory && filterLabelRef.current) {
      filterLabelRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedSubcategory]);

  const handleCategorySelection = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory('');
  };

  const handleSubcategorySelection = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  return (
    <div className="container">
      <div className="our-products-section text-center my-5">
        <h2>Categories</h2>

        <div className="category-selection row">
          {categories.map((category) => (
            <div
              key={category.id}
              className="col-sm-6 col-md-3 category"
              onClick={() => handleCategorySelection(category.name)}
            >
              <img src={category.image} alt={category.name} />
              <p>{category.name}</p>
            </div>
          ))}
        </div>

        {selectedCategory && (
          <>
            <h3>Subcategories</h3>
            <div className="subcategory-selection row mt-4">
              {subcategories.map((subcategory, index) => (
                <div
                  key={index}
                  className="col-sm-6 col-md-3 subcategory"
                  onClick={() => handleSubcategorySelection(subcategory)}
                >
                  <img src={subcategoryImages[subcategory] || `https://example.com/path/to/default.jpg`} alt={subcategory} />
                  <p>{subcategory}</p>
                </div>
              ))}
            </div>
          </>
        )}

        <p ref={filterLabelRef} className="filter-label mt-4">
          Showing {selectedCategory} {selectedSubcategory ? ` - ${selectedSubcategory}` : ''} Products
        </p>

        <div className="row">
          {filteredProducts.map(product => (
            <div key={product.id} className="col-md-3 product">
              <Link to={`/product/${product.id}`}>
                <img src={product.image} alt={product.name} />
              </Link>
              <p>{product.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Products;
