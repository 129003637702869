import React, { useEffect, useState } from 'react';
import { firestore, storage } from '../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './ProductList.css';

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [editState, setEditState] = useState({});
  const [newCategory, setNewCategory] = useState('');
  const [newSubcategory, setNewSubcategory] = useState({ name: '', image: '' });
  const [newProduct, setNewProduct] = useState({
    modelNumber: '',
    name: '',
    category: '',
    subcategory: '',
    details: '',
    image: '',
  });

  const productsCollectionRef = collection(firestore, 'products');
  const categoriesCollectionRef = collection(firestore, 'categories');

  useEffect(() => {
    const fetchProducts = async () => {
      const data = await getDocs(productsCollectionRef);
      const productsData = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      productsData.sort((a, b) => a.modelNumber.localeCompare(b.modelNumber));
      setProducts(productsData);
    };

    const fetchCategories = async () => {
      const data = await getDocs(categoriesCollectionRef);
      setCategories(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    fetchProducts();
    fetchCategories();
  }, []);

  const handleImageUpload = async (e, type, id, subcategoryName = '') => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const storageRef = ref(storage, `${type}/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);

      if (type === 'products') {
        await updateDoc(doc(firestore, 'products', id), {
          image: downloadURL,
        });
        setProducts((prevProducts) =>
          prevProducts.map((product) =>
            product.id === id ? { ...product, image: downloadURL } : product
          )
        );
      } else if (type === 'categories') {
        if (subcategoryName) {
          // Handle subcategory image upload
          const category = categories.find((cat) => cat.id === id);
          const updatedSubcategories = category.subcategories.map((sub) =>
            sub.name === subcategoryName ? { ...sub, image: downloadURL } : sub
          );
          await updateDoc(doc(firestore, 'categories', id), {
            subcategories: updatedSubcategories,
          });
          setCategories((prevCategories) =>
            prevCategories.map((cat) =>
              cat.id === id ? { ...cat, subcategories: updatedSubcategories } : cat
            )
          );
        } else {
          // Handle category image upload
          await updateDoc(doc(firestore, 'categories', id), {
            image: downloadURL,
          });
          setCategories((prevCategories) =>
            prevCategories.map((category) =>
              category.id === id ? { ...category, image: downloadURL } : category
            )
          );
        }
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleAddCategory = async () => {
    try {
      const newCategoryData = { name: newCategory, subcategories: [], image: '' };
      const docRef = await addDoc(categoriesCollectionRef, newCategoryData);
      setCategories([...categories, { ...newCategoryData, id: docRef.id }]);
      setNewCategory('');
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteDoc(doc(firestore, 'categories', categoryId));
      setCategories(categories.filter((category) => category.id !== categoryId));
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  const handleAddSubcategory = async (categoryId) => {
    try {
      const categoryDocRef = doc(firestore, 'categories', categoryId);
      const category = categories.find((cat) => cat.id === categoryId);
      const updatedSubcategories = [...category.subcategories, newSubcategory];
      await updateDoc(categoryDocRef, { subcategories: updatedSubcategories });
      setCategories(
        categories.map((cat) => (cat.id === categoryId ? { ...cat, subcategories: updatedSubcategories } : cat))
      );
      setNewSubcategory({ name: '', image: '' });
    } catch (error) {
      console.error('Error adding subcategory:', error);
    }
  };

  const handleDeleteSubcategory = async (categoryId, subcategoryName) => {
    try {
      const categoryDocRef = doc(firestore, 'categories', categoryId);
      const category = categories.find((cat) => cat.id === categoryId);
      const updatedSubcategories = category.subcategories.filter((sub) => sub.name !== subcategoryName);
      await updateDoc(categoryDocRef, { subcategories: updatedSubcategories });
      setCategories(
        categories.map((cat) => (cat.id === categoryId ? { ...cat, subcategories: updatedSubcategories } : cat))
      );
    } catch (error) {
      console.error('Error deleting subcategory:', error);
    }
  };

  const handleAddProduct = async () => {
    try {
      const docRef = await addDoc(productsCollectionRef, newProduct);
      const newProductData = { ...newProduct, id: docRef.id };
      setProducts((prevProducts) => {
        const updatedProducts = [...prevProducts, newProductData];
        updatedProducts.sort((a, b) => a.modelNumber.localeCompare(b.modelNumber));
        return updatedProducts;
      });
      setNewProduct({ modelNumber: '', name: '', category: '', subcategory: '', details: '', image: '' });
    } catch (error) {
      console.error('Error adding product:', error);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await deleteDoc(doc(firestore, 'products', productId));
      setProducts(products.filter((product) => product.id !== productId));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleEditChange = (event, productId, field) => {
    const newValue = event.target.value;
    setEditState((prevEditState) => ({
      ...prevEditState,
      [productId]: {
        ...prevEditState[productId],
        [field]: newValue,
      },
    }));
  };

  const handleSaveProduct = async (productId) => {
    const productUpdates = editState[productId];
    if (productUpdates) {
      await updateDoc(doc(firestore, 'products', productId), productUpdates);
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === productId ? { ...product, ...productUpdates } : product
        )
      );
      setEditState((prevEditState) => {
        const updatedEditState = { ...prevEditState };
        delete updatedEditState[productId];
        return updatedEditState;
      });
    }
  };

  return (
    <div className="product-list">
      <div className="category-management">
        <h2>Manage Categories</h2>
        <input
          type="text"
          placeholder="New Category"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <button onClick={handleAddCategory} className="add-category-btn">Add Category</button>
        <ul>
          {categories.map((category) => (
            <li key={category.id}>
              <div className="category-item">
                <span>{category.name}</span>
                {category.image && (
                  <img src={category.image} alt={category.name} className="category-image" />
                )}
                <input type="file" onChange={(e) => handleImageUpload(e, 'categories', category.id)} />
                <button onClick={() => handleDeleteCategory(category.id)} className="delete-category-btn">Delete</button>
              </div>
              <ul>
                {category.subcategories.map((subcategory) => (
                  <li key={subcategory.name}>
                    <span>{subcategory.name}</span>
                    {subcategory.image && (
                      <img src={subcategory.image} alt={subcategory.name} className="category-image" />
                    )}
                    <input type="file" onChange={(e) => handleImageUpload(e, 'categories', category.id, subcategory.name)} />
                    <button onClick={() => handleDeleteSubcategory(category.id, subcategory.name)} className="delete-category-btn">Delete</button>
                  </li>
                ))}
                <li>
                  <input
                    type="text"
                    placeholder="New Subcategory Name"
                    value={newSubcategory.name}
                    onChange={(e) => setNewSubcategory({ ...newSubcategory, name: e.target.value })}
                  />
                  <input
                    type="file"
                    onChange={(e) => handleImageUpload(e, 'subcategories')}
                  />
                  <button onClick={() => handleAddSubcategory(category.id)} className="add-category-btn">Add Subcategory</button>
                </li>
              </ul>
            </li>
          ))}
        </ul>
      </div>

      <div className="new-product-form">
        <input
          type="text"
          placeholder="Model Number"
          value={newProduct.modelNumber}
          onChange={(e) => setNewProduct({ ...newProduct, modelNumber: e.target.value })}
        />
        <input
          type="text"
          placeholder="Name"
          value={newProduct.name}
          onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
        />
        <select
          value={newProduct.category}
          onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
        >
          <option value="">Select Category</option>
          {categories.map((category) => (
            <option key={category.id} value={category.name}>
              {category.name}
            </option>
          ))}
        </select>
        <select
          value={newProduct.subcategory}
          onChange={(e) => setNewProduct({ ...newProduct, subcategory: e.target.value })}
          disabled={!newProduct.category}
        >
          <option value="">Select Subcategory</option>
          {categories
            .find((category) => category.name === newProduct.category)?.subcategories.map((subcategory) => (
              <option key={subcategory.name} value={subcategory.name}>
                {subcategory.name}
              </option>
            ))}
        </select>
        <textarea
          placeholder="Details"
          value={newProduct.details}
          onChange={(e) => setNewProduct({ ...newProduct, details: e.target.value })}
        />
        <input
          type="file"
          onChange={(e) => handleImageUpload(e, 'products', newProduct.id)}
        />
        <button onClick={handleAddProduct} className="add-product-btn">Add Product</button>
      </div>

      <div className="product-listing">
        <h2>Product List</h2>
        <ul>
          {products.map((product) => (
            <li key={product.id}>
              {editState[product.id] ? (
                <div className="product-edit-form">
                  <input
                    type="text"
                    value={editState[product.id].modelNumber || product.modelNumber}
                    onChange={(e) => handleEditChange(e, product.id, 'modelNumber')}
                  />
                  <input
                    type="text"
                    value={editState[product.id].name || product.name}
                    onChange={(e) => handleEditChange(e, product.id, 'name')}
                  />
                  <input
                    type="text"
                    value={editState[product.id].category || product.category}
                    onChange={(e) => handleEditChange(e, product.id, 'category')}
                  />
                  <input
                    type="text"
                    value={editState[product.id].subcategory || product.subcategory}
                    onChange={(e) => handleEditChange(e, product.id, 'subcategory')}
                  />
                  <textarea
                    value={editState[product.id].details || product.details}
                    onChange={(e) => handleEditChange(e, product.id, 'details')}
                  />
                  <button onClick={() => handleSaveProduct(product.id)} className="save-product-btn">Save</button>
                </div>
              ) : (
                <div className="product-item">
                  <span className="product-item-name">{product.modelNumber}</span>
                  <span className="product-item-name">{product.name}</span>
                  <span className="product-item-name">{product.category}</span>
                  <span className="product-item-name">{product.subcategory}</span>
                  <span className="product-item-name">{product.details}</span>
                  {product.image && (
                    <img src={product.image} alt={product.name} className="product-image" />
                  )}
                  <input type="file" onChange={(e) => handleImageUpload(e, 'products', product.id)} />
                  <button onClick={() => setEditState({ ...editState, [product.id]: { ...product } })} className="edit-product-btn">Edit</button>
                  <button onClick={() => handleDeleteProduct(product.id)} className="delete-product-btn">Delete</button>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProductList;
