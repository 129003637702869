import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <footer className="footer bg-dark">
            <div className="container">
                <div className="row">
                    {/* Column 1 */}
                    <div className="col-md-4">
                        <h5>MEHTAB PRODUCTS</h5>
                        <p>Mehtab Products is one of the leading manufacturer & exporter of fashion belt & leather goods in India. We have an experienced and professional team with latest machinery plant. We use all types of leather as in high wage and chrome leather.</p>
                    </div>
                    {/* Column 2 */}
                    <div className="col-md-4">
                        <h5>QUICK MENU</h5>
                        <ul className="list-unstyled">
                            <li><a href="/belts">Leather Belts</a></li>
                            <li><a href="/products">Leather Bags</a></li>
                            <li><a href="/products">Leather Wallets</a></li>
                            <li><a href="/products">Accessories</a></li>
                        </ul>
                    </div>
                    {/* Column 3 */}
                    <div className="col-md-4">
                        <h5>CONTACT US</h5>
                        <address>
                            Mishari House<br />
                            912 K Wajidpur, Jajmau, Kanpur (U.P) – INDIA<br />
                            +91-8318003947<br />
                            +91-9839373955<br />
                            <a href="mailto:manaal@mehtabproduct.com">manaal@mehtabproduct.com</a><br />
                            <a href="mailto:mehtabpro786@gmail.com">mehtabpro786@gmail.com</a>
                        </address>
                    </div>
                </div>
                <div className="row bottom-line">
                    <p>Copyright © Mehtab Products 2023. All Right Reserved</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;