import React, { useState, useEffect } from 'react';
import MehtabProductsLogo from '../assets/images/MehtabProductsLogo.jpg';
import './Header.css';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

function Header() {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, 'categories'));
      const fetchedCategories = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Sort categories with "Men's" first, then reverse alphabetical order for the rest
      fetchedCategories.sort((a, b) => {
        if (a.name === "Men's") return -1;
        if (b.name === "Men's") return 1;
        return b.name.localeCompare(a.name); // Reverse alphabetical order
      });

      setCategories(fetchedCategories);
    };

    fetchCategories();
  }, [db]);

  const handleCategoryClick = (category) => {
    navigate('/products', { state: { selectedCategory: category } });
  };

  return (
    <header className="header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-12 logo">
            <img src={MehtabProductsLogo} alt="Mehtab Products Logo" className="img-fluid" />
          </div>
          <nav className="col-lg-8 col-md-12">
            <ul className="nav justify-content-end">
              <li className="nav-item">
                <NavLink exact className="nav-link" activeClassName="active" to="/">HOME</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/about-us">ABOUT US</NavLink>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="/products" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  PRODUCTS
                </Link>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  {categories.map(category => (
                    <li key={category.id}>
                      <a className="dropdown-item" onClick={() => handleCategoryClick(category.name)}>
                        {category.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/contact-us">CONTACT US</NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
