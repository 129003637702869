import React, { useState, useEffect, useRef } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { Link } from 'react-router-dom';
import './Products.css';

import BraidedBeltsImg from '../../assets/images/braidedbeltscategory.jpeg';
import CasualBeltsImg from '../../assets/images/casualbeltscategory.jpeg';
import EmbossedBeltsImg from '../../assets/images/embossingbeltscategory.jpeg';
import FormalBeltsImg from '../../assets/images/formalbeltscategory.jpeg';
import HairOnBeltsImg from '../../assets/images/haironbeltscategory.jpeg';
import ToolingBeltsImg from '../../assets/images/toolingbeltscategory.jpeg';

function Belts() {
  const [belts, setBelts] = useState([]);
  const [filteredBelts, setFilteredBelts] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState('All');
  const filterLabelRef = useRef(null); // Create a ref for the filter label

  useEffect(() => {
    const fetchBelts = async () => {
      const querySnapshot = await getDocs(collection(firestore, "products"));
      const beltsArray = [];
      querySnapshot.forEach((doc) => {
        const product = { id: doc.id, ...doc.data() };
        if (product.category === 'Belt') {
          beltsArray.push(product);
        }
      });
      setBelts(beltsArray);
      setFilteredBelts(beltsArray);
    };

    fetchBelts();
  }, []);

  useEffect(() => {
    // Sort belts by model number
    const sortedBelts = [...belts].sort((a, b) => a.modelNumber.localeCompare(b.modelNumber));

    // Filter by selected subcategory
    const filtered = selectedSubcategory === 'All'
      ? sortedBelts
      : sortedBelts.filter(belt => belt.subcategory === selectedSubcategory);

    setFilteredBelts(filtered);
    if (filterLabelRef.current) {
      filterLabelRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [selectedSubcategory]);

  const handleSubcategorySelection = (subcategory) => {
    setSelectedSubcategory(subcategory);
  };

  // A function to determine the display text based on the selected subcategory
  const displaySubcategoryText = (subcategory) => {
    if (subcategory === '-') {
      return 'Braided Belts';
    }
    return subcategory;
  };

  return (
    <div className="container">
      <div className="our-products-section text-center my-5">
        <h2>OUR BELTS</h2>
        <div className="category-selection row">
          <div className="col-sm-6 col-md-3 product" onClick={() => handleSubcategorySelection('-')}>
            <img src={BraidedBeltsImg} alt="Braided Belts" />
            <p>Braided Belts</p>
          </div>
          <div className="col-sm-6 col-md-3 product" onClick={() => handleSubcategorySelection('Casual Belts')}>
            <img src={CasualBeltsImg} alt="Casual Belts" />
            <p>Casual Belts</p>
          </div>
          <div className="col-sm-6 col-md-3 product" onClick={() => handleSubcategorySelection('Embossed Belts')}>
            <img src={EmbossedBeltsImg} alt="Embossed Belts" />
            <p>Embossed Belts</p>
          </div>
          <div className="col-sm-6 col-md-3 product" onClick={() => handleSubcategorySelection('Formal Belts')}>
            <img src={FormalBeltsImg} alt="Formal Belts" />
            <p>Formal Belts</p>
          </div>
          <div className="col-sm-6 col-md-3 product" onClick={() => handleSubcategorySelection('Hair On Belts')}>
            <img src={HairOnBeltsImg} alt="Hair On Belts" />
            <p>Hair On Belts</p>
          </div>
          <div className="col-sm-6 col-md-3 product" onClick={() => handleSubcategorySelection('Tooling Belts')}>
            <img src={ToolingBeltsImg} alt="Tooling Belts" />
            <p>Tooling Belts</p>
          </div>
        </div>
        <p ref={filterLabelRef} className="filter-label text-center my-3">
          Showing {selectedSubcategory === 'All' ? 'All' : displaySubcategoryText(selectedSubcategory)}
        </p>
        <div className="row">
          {filteredBelts.map(belt => (
            <div key={belt.id} className="col-md-3 product">
              <Link to={`/product/${belt.id}`}>
                <img src={belt.image} className="img-fluid" alt={belt.name} />
              </Link>
              <p>{belt.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Belts;